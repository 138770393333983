.editListItem {
    padding: 10px;
    background-color: var(--light-contrast);
    list-style: none;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
}

.editList {
    padding: 0 10px;
}

.editListIcons {
    margin-right: 0;
    margin-left: auto;
}

.editListIcons > * {
    margin: 0 5px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.editListEditIcon {
    color: var(--manage-color);
}

.editListCopyIcon {
    color: var(--info-color);
}

.editListCancelIcon {
    color: var(--cancel-color);
}

.editListEditIcon:hover {
    color: var(--manage-color-hover);
}

.editListCopyIcon:hover {
    color: var(--info-color-hover);
}

.editListCancelIcon:hover {
    color: var(--cancel-color-hover);
}